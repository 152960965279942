import { map, propOr, prop, pathOr, compose } from 'ramda'
import { createSelector } from 'reselect'

export const getIsLoading = createSelector(
  state => state,
  state => propOr(false, 'isLoading', state)
)

export const getIsLoaded = createSelector(
  state => state,
  state => propOr(false, 'isLoaded', state)
)

export const getSelectedContractorIndex = createSelector(
  state => state,
  state => propOr(-1, 'selectedContractorIndex', state)
)

export const getContractorValues = createSelector(
  state => state,
  state => compose(map(ca => ({
    title: propOr('', 'TITLE', ca),
    value: propOr('', 'ID', ca)
  })), propOr([], 'contractorValues'))(state)
)

export const getOrganizations = createSelector(
  state => state,
  state => compose(map(org => ({
    title: propOr('', 'TITLE', org),
    value: propOr('', 'ID', org)
  })),propOr([], 'organizations'))(state)
)

export const getMaxDate = createSelector(
  state => state,
  state => pathOr(0, ['date', 'max'], state)
)

export const getMinDate = createSelector(
  state => state,
  state => pathOr(0, ['date', 'min'], state)
)

export const getDateRegisterClose = createSelector(
  state => state,
  state => propOr(0, 'dateRegisterClose', state)
)

export const getIsError = createSelector(
  state => state,
  state => propOr(false, 'isError', state)
)

export const getActs = createSelector(
  state => state,
  ({ items, status, bookkeepingOrgs, bookkeepingContractors }) =>
    map(
      item => ({
        organization: propOr(
          {},
          prop('UF_BOOKKEEPING_ORG', item),
          bookkeepingOrgs
        ),
        contractor: propOr(
          {},
          prop('UF_CONTRACTOR', item),
          bookkeepingContractors
        ),
        dateFrom: propOr(-1, 'UF_DATE_FROM', item),
        dateTo: propOr(-1, 'UF_DATE_TO', item),
        filePath: propOr('', 'UF_FILE_PATH', item),
        status: propOr(
          'inProgress',
          'CODE',
          prop(prop('UF_STATUS', item), status)
        ),
        id: propOr('', 'ID', item),
        dateCreate: propOr(-1, 'UF_DATE_CREATE', item)
      }),
      items
    )
)
