/* eslint-disable complexity */
import React, { useEffect } from 'react'

import cx from 'classnames'
import { array, bool, func, number, string } from 'prop-types'
import { pathOr, prop, propOr, slice, toUpper } from 'ramda'
import { connect } from 'react-redux'

import CurrencyValue from 'components/CurrencyValue'
import Icon from 'components/Icon'
import Link from 'components/Link'
import Picture from 'components/Picture'
import { CartProducts } from 'components/Placeholder'
import { getBasketForPoints } from 'helpers/products'
import {
  fetchCartItems,
  productsSelector,
  isLoadingProductsSelector
} from 'redux/modules/productList'
import formatAmount from 'utils/formatAmount'
import noun from 'utils/noun'

import s from './CartMini.scss'

const PRODUCT_CNT = 5

const Products = ({
  productCnt,
  catalog,
  cart,
  items,
  onFetchCartItems,
  isLoading
}) => {
  useEffect(() => {
    onFetchCartItems({ catalog, value: cart, count: PRODUCT_CNT })
  }, [cart])

  if (isLoading) {
    return <CartProducts />
  }

  if (items.length === 0) {
    return <div className={s.empty}>Ваша корзина пуста</div>
  }

  return (
    <div className={s.products}>
      {slice(0, PRODUCT_CNT, items).map(item => {
        const cnt = pathOr(
          0,
          ['BASKETS', toUpper(catalog), cart, 'QUANTITY'],
          item
        )
        const isPoints = getBasketForPoints(item, [catalog, cart])
        return (
          <div key={prop('id', item)}
            className={s.product}>
            <div className={s.productImage}>
              <Picture
                backgroundSize='contain'
                className={s.image}
                src={propOr('', 'PREVIEW_PICTURE_PATH', item)}
                alt={propOr('', 'NAME', item)}
              />
            </div>
            <div className={s.productName}>{propOr('', 'NAME', item)}</div>
            <div className={s.productCount}>{formatAmount(cnt)} шт.</div>
            <div className={s.productPrice}>
              <CurrencyValue
                price={propOr(0, 'SUM', item)}
                isPoints={isPoints}
                className={cx({
                  [s.isPoints]: isPoints
                })}
              />
            </div>
          </div>
        )
      })}
      {productCnt > PRODUCT_CNT ? (
        <Link to={`/basket/${catalog}/${cart}`}>
          <div className={s.more}>
            <Icon icon='cart'
              className={s.moreIcon}
              size='big' />
            <span className={s.moreText}>
              Показать еще {productCnt - PRODUCT_CNT}{' '}
              {noun(productCnt - PRODUCT_CNT, ['товар', 'товара', 'товаров'])}
            </span>
          </div>
        </Link>
      ) : (
        <div />
      )}
    </div>
  )
}

Products.propTypes = {
  items: array,
  catalog: string,
  cart: number,
  productCnt: number,
  isLoading: bool,
  onFetchCartItems: func
}

export default connect(
  ({ productList }) => ({
    isLoading: isLoadingProductsSelector(productList),
    items: productsSelector(productList)
  }),
  {
    onFetchCartItems: fetchCartItems
  }
)(Products)
