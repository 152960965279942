/* eslint-disable react/prop-types */
import React from 'react'

import { pathOr, slice } from 'ramda'
import { Redirect } from 'react-router'

import ServerError from 'components/ServerError'
import App from 'containers/App'
import bookmark from 'decorators/bookmark'
import PageActual from 'pages/Actual'
import PageActualItem from 'pages/ActualItem'
import PageAnalytics from 'pages/Analytics'
import PageApi from 'pages/Api'
import PageAuth from 'pages/Auth'
import PageBasket from 'pages/Basket'
import PageBasketLoyalty from 'pages/BasketLoyalty'
import PageBookkeeping from 'pages/Bookkeeping'
import PageCabinet from 'pages/Cabinet'
import PageCabinetCompanies from 'pages/CabinetCompanies'
import PageCabinetMain from 'pages/CabinetMain'
import PageCatalog from 'pages/Catalog'
import PageCatalogBookmark from 'pages/CatalogBookmark'
import PageCatalogFavorite from 'pages/CatalogFavorite'
import PageCatalogList from 'pages/CatalogList'
import PageCatalogListItem from 'pages/CatalogListItem'
import CatalogLoyalty from 'pages/CatalogLoyalty'
import PageCheckout from 'pages/Checkout'
import PageClaim from 'pages/Claim'
import PageClaimEdit from 'pages/ClaimEdit'
import PageClaimInvoice from 'pages/ClaimInvoice'
import PageClaimInvoices from 'pages/ClaimInvoices'
import PageClaimThanks from 'pages/ClaimThanks'
import PageCompare from 'pages/Compare'
import PageContent from 'pages/Content'
import PageContentItems from 'pages/ContentItems'
import PageContentMain from 'pages/ContentMain'
import PageDirectory from 'pages/Directory'
import PageDiscounts from 'pages/Discounts'
import PageDocument from 'pages/Document'
import PageDocumentMain from 'pages/DocumentMain'
import PageEmployeeConfirm from 'pages/EmployeeConfirm'
import PageEmployees from 'pages/Employees'
import PageFaq from 'pages/Faq'
import PageFaqItem from 'pages/FaqItem'
import PageFeedback from 'pages/Feedback'
import PageHome from 'pages/Home'
import PageInvoiceBillContainer from 'pages/InvoiceBill'
import PageInvoicesContainer from 'pages/Invoices'
import PageInvoiceShipmentContainer from 'pages/InvoiceShipment'
import PageLoyalty from 'pages/Loyalty'
import PageLoyaltyCashback from 'pages/LoyaltyCashback'
import PageLoyaltyContent from 'pages/LoyaltyContent'
import PageLoyaltyCounter from 'pages/LoyaltyCounter'
import PageLoyaltyHome from 'pages/LoyaltyHome'
import PageLoyaltyOrderThanks from 'pages/LoyaltyOrderThanks'
import PageMark from 'pages/Mark'
import PageNotFound from 'pages/NotFound'
import PageNotifications from 'pages/Notifications'
import PageOffer from 'pages/Offer'
import PageOffers from 'pages/Offers'
import PageOffersMain from 'pages/OffersMain'
import PageOffices from 'pages/Offices'
import PageOperationsHistory from 'pages/OperationsHistory'
import PageOrderThanks from 'pages/OrderThanks'
import PageProduct from 'pages/Product'
import PageProductRedirect from 'pages/ProductRedirect'
import PageRecovery from 'pages/Recovery'
import PageRegistrationConfirm from 'pages/RegistrationConfirm'
import PageSchedule from 'pages/Schedule'
import PageSearch from 'pages/Search'
import PageSearchBookmark from 'pages/SearchBookmark'
import PageSearchLoyalty from 'pages/SearchLoyalty'
import PageSearchModeration from 'pages/SearchModeration'
import PageSitemap from 'pages/Sitemap'
import PageStorage from 'pages/Storage'
import PageStorageSection from 'pages/StorageSection'

const prepareRedirect = (props, key = 'id') =>
  pathOr('', ['match', 'params', key], props)

const routes = [
  {
    component: App,
    routes: [
      {
        path: '/:url*(/+)',
        component: props => (
          <Redirect
            to={`${slice(0, -1, props.location.pathname)}${
              props.location.search ? `${props.location.search}` : ''
            }`}
          />
        )
      },
      {
        path: '/',
        exact: true,
        component: PageHome
      },
      {
        path: '/auth',
        component: PageAuth
      },
      {
        path: '/basket/:catalog/:id',
        component: PageBasket
      },
      {
        path: '/basket/:catalog',
        component: PageBasket
      },
      {
        path: '/basket',
        component: PageBasket
      },
      {
        path: '/bookmark/catalog-list/:bookmark/:id',
        component: props => (
          <Redirect
            to={`/bookmark/${prepareRedirect(
              props,
              'bookmark'
            )}/catalog/sections/${prepareRedirect(props, 'id')}`}
          />
        )
      },
      {
        path: '/bookmark/catalog-list/:bookmark',
        component: props => (
          <Redirect
            to={`/bookmark/${prepareRedirect(props, 'bookmark')}/catalog`}
          />
        )
      },
      {
        path: '/bookmark/catalog/:bookmark/:id',
        component: props => (
          <Redirect
            to={`/bookmark/${prepareRedirect(
              props,
              'bookmark'
            )}/catalog/sections/${prepareRedirect(props, 'id')}`}
          />
        )
      },
      {
        path: '/bookmark/product/:bookmark/:id',
        component: props => (
          <Redirect
            to={`/bookmark/${prepareRedirect(
              props,
              'bookmark'
            )}/catalog/product/${prepareRedirect(props, 'id')}`}
          />
        )
      },
      {
        path: '/bookmark/search/:bookmark',
        component: props => (
          <Redirect
            to={`/bookmark/${prepareRedirect(props, 'bookmark')}/search`}
          />
        )
      },
      {
        path: '/bookmark-catalog',
        component: () => <Redirect to='/bookmark' />
      },
      {
        path: '/bookmark/:bookmark/search',
        component: bookmark()(PageSearchBookmark)
      },
      {
        path: '/bookmark/:bookmark/catalog/product/:id',
        mode: 'bookmark',
        component: bookmark()(PageProduct)
      },
      {
        path: '/bookmark/:bookmark/catalog/products/:id',
        requestParams: {
          section_id: ':id',
          type: 'catalog',
          bookmark: ':bookmark'
        },
        type: 'section',
        requestPath: 'catalog/bookmark/:bookmark/section/:id',
        component: bookmark()(PageCatalog)
      },
      {
        path: '/bookmark/:bookmark/catalog/sections/:id',
        component: bookmark()(PageCatalogListItem)
      },
      {
        path: '/bookmark/:bookmark/catalog',
        component: bookmark()(PageCatalogList)
      },
      {
        path: '/bookmark',
        component: PageCatalogBookmark
      },
      {
        path: '/brands',
        component: () => <Redirect to='/brand' />
      },
      {
        path: '/brand',
        type: 'tm',
        component: PageDirectory
      },
      {
        path: '/cabinet',
        component: PageCabinet,
        routes: [
          {
            path: '/cabinet/exchange',
            component: PageApi
          },
          {
            path: '/cabinet/api',
            component: () => <Redirect to='/cabinet/exchange' />
          },
          {
            path: '/cabinet/company',
            component: PageCabinetCompanies
          },
          {
            path: '/cabinet/companies',
            component: () => <Redirect to='/cabinet/company' />
          },
          {
            path: '/cabinet/schedule',
            component: PageSchedule
          },
          {
            path: '/cabinet/employee',
            component: PageEmployees
          },
          {
            path: '/cabinet/feedback/:type?',
            component: PageFeedback,
            requestParams: { type: ':type' }
          },
          {
            path: '/cabinet/invoice/bill/:id',
            component: PageInvoiceBillContainer,
            requestParams: {
              id: ':id'
            }
          }, {
            path: '/cabinet/invoice/shipment/:id',
            component: PageInvoiceShipmentContainer,
            requestParams: {
              id: ':id'
            }
          }, {
            path: '/cabinet/invoice',
            component: PageInvoicesContainer
          },
          {
            path: '/cabinet/invoices/:type',
            component: () => <Redirect to='/cabinet/invoice' />
          },
          {
            path: '/cabinet/invoices',
            component: () => <Redirect to='/cabinet/invoice' />
          },
          {
            path: '/cabinet/notification',
            component: PageNotifications
          },
          {
            path: '/cabinet/notifications',
            component: () => <Redirect to='/cabinet/notification' />
          },
          {
            path: '/cabinet/discount',
            component: PageDiscounts
          },
          {
            path: '/cabinet/discounts',
            component: () => <Redirect to='/cabinet/discount' />
          },
          {
            path: '/cabinet/bookkeeping(|/:type)',
            component: PageBookkeeping
          },
          {
            path: '/cabinet/claim/invoice/:id',
            component: props => (
              <Redirect to={`/cabinet/claim/invoice-products/${prepareRedirect(props, 'id')}`} />
            )
          },
          {
            path: '/cabinet/claim/invoice',
            component: PageClaimInvoices
          },
          {
            path: '/cabinet/claim/invoice-products/:id',
            component: PageClaimInvoice
          },
          {
            path: '/cabinet/claim/:id/(draft|inprogress|completed)',
            component: PageClaimEdit
          },
          {
            path: '/cabinet/claim/:id/thanks',
            component: PageClaimThanks
          },
          {
            path: '/cabinet/claims/:tab?',
            component: props => (
              <Redirect to={`/cabinet/claim/${prepareRedirect(props, 'tab')}`} />
            )
          },
          {
            path: '/cabinet/claim/:tab?',
            component: PageClaim,
            requestParams: { tab: ':tab' }
          },
          {
            path: '/cabinet/analytics',
            component: PageAnalytics
          },
          {
            path: '/cabinet',
            component: PageCabinetMain
          }
        ]
      },
      {
        path: '/favorite',
        component: PageCatalogFavorite
      },
      {
        path: '/catalog-list/:id',
        component: props => (
          <Redirect to={`/catalog/sections/${prepareRedirect(props, 'id')}`} />
        )
      },
      {
        path: '/catalog/sections/:id',
        component: PageCatalogListItem
      },
      {
        path: '/catalog-list',
        component: () => <Redirect to='/catalog' />
      },
      {
        path: '/catalog/favorite',
        component: () => <Redirect to='/favorite' />
      },
      {
        path: '/catalog/offers/offline/:id',
        type: 'offer',
        requestParams: { type: 'catalog' },
        requestPath: 'catalog/offers/offline/:id/products',
        component: PageCatalog
      },
      {
        path: '/catalog/offers/markdown/:id',
        type: 'markdown',
        requestParams: { type: 'catalog' },
        requestPath: 'catalog/main/mark/:id/section',
        component: PageCatalog
      },
      {
        path: '/catalog/country/:id',
        type: 'country',
        requestParams: { type: 'catalog' },
        requestPath: '/catalog/main/directory/country/:id/section',
        component: PageCatalog
      },
      {
        path: '/catalog/ruler/:id',
        type: 'ruler',
        requestParams: { type: 'catalog' },
        requestPath: 'catalog/main/directory/ruler/:id/section',
        component: PageCatalog
      },
      {
        path: '/catalog/brand/:id',
        type: 'brand',
        requestParams: { type: 'catalog' },
        requestPath: 'catalog/main/directory/tm/:id/section',
        component: PageCatalog
      },
      {
        path: '/catalog/manufacture/:id',
        type: 'manufacture',
        requestParams: { type: 'catalog' },
        requestPath: 'catalog/main/directory/manufacture/:id/section',
        component: PageCatalog
      },
      {
        path: '/catalog/marks/:id',
        component: props => (
          <Redirect to={`/catalog/mark/${prepareRedirect(props, 'id')}`} />
        )
      },
      {
        path: '/catalog/mark/:id',
        type: 'mark',
        requestParams: { type: 'catalog' },
        requestPath: 'catalog/main/mark/:id/section',
        component: PageCatalog
      },
      {
        path: '/catalog/product/:id',
        mode: 'catalog',
        component: PageProduct
      },
      {
        path: '/catalog/products/:id',
        requestParams: { section_id: ':id', type: 'catalog' },
        type: 'section',
        requestPath: 'catalog/main/section/:id',
        component: PageCatalog
      },
      {
        path: '/catalog/mark',
        component: PageMark
      },
      {
        path: '/catalog',
        component: PageCatalogList
      },
      {
        path: '/compare',
        component: PageCompare
      },
      {
        path: '/content/:type/:id',
        component: PageContent
      },
      {
        path: '/content/:type',
        component: PageContentItems
      },
      {
        path: '/content',
        component: PageContentMain
      },
      {
        path: '/country',
        type: 'country',
        component: PageDirectory
      },
      {
        path: '/c/:id',
        component: PageContent
      },
      {
        path: '/documents/:slug',
        component: PageDocument
      },
      {
        path: '/documents',
        component: PageDocumentMain
      },
      {
        path: '/employee-confirm',
        component: PageEmployeeConfirm
      },
      {
        path: '/faq/items/:id',
        component: props => (
          <Redirect to={`/faq/${prepareRedirect(props, 'id')}`} />
        )
      },
      {
        path: '/faq/:id',
        component: PageFaqItem
      },
      {
        path: '/faq',
        component: PageFaq
      },
      {
        path: '/info/:id',
        component: PageActualItem
      },
      {
        path: '/info',
        component: PageActual
      },
      {
        path: '/loyalty',
        component: PageLoyalty,
        routes: [
          {
            path: '/loyalty/basket',
            component: PageBasketLoyalty
          },
          {
            path: '/loyalty/catalog/products/:id',
            type: 'loyalty',
            requestParams: { section_id: ':id', type: 'catalog' },
            requestPath: 'catalog/loyalty/section',
            component: CatalogLoyalty
          },
          {
            path: '/loyalty/catalog/product/:id',
            mode: 'loyalty',
            component: PageProduct
          },
          {
            path: '/loyalty/catalog/all',
            component: () => <Redirect to='/loyalty/catalog' />
          },
          {
            path: '/loyalty/catalog/:id',
            component: props => (
              <Redirect
                to={`/loyalty/catalog/products/${prepareRedirect(props, 'id')}`}
              />
            )
          },
          {
            path: '/loyalty/catalog',
            type: 'loyalty',
            requestParams: { type: 'catalog' },
            requestPath: 'catalog/loyalty/section',
            component: CatalogLoyalty
          },
          {
            path: '/loyalty/morepoints',
            component: PageLoyaltyCashback
          },
          {
            path: '/loyalty/orders/thanks/:id',
            mode: 'loyalty',
            component: PageLoyaltyOrderThanks
          },
          {
            path: '/loyalty/statuses',
            component: PageLoyaltyCounter
          },
          {
            path: '/loyalty/operations',
            component: PageOperationsHistory
          },
          {
            path: '/loyalty/product/:id',
            component: props => (
              <Redirect
                to={`/loyalty/catalog/product/${prepareRedirect(props, 'id')}`}
              />
            )
          },
          {
            path: '/loyalty/search',
            component: PageSearchLoyalty
          },
          {
            path: '/loyalty/:type',
            component: PageLoyaltyContent
          },
          {
            path: '/loyalty',
            component: PageLoyaltyHome
          }
        ]
      },
      {
        path: '/news/:id',
        component: props => (
          <Redirect to={`/content/news/${prepareRedirect(props, 'id')}`} />
        )
      },
      {
        path: '/news',
        component: <Redirect to='/content/news' />
      },
      {
        path: '/offer/:id',
        component: PageOffer
      },
      {
        path: '/offers/offline',
        component: () => <Redirect to='/offers' />
      },
      {
        path: '/offers/:title',
        component: PageOffers
      },
      {
        path: '/offers',
        component: PageOffersMain
      },
      {
        path: '/offices',
        component: PageOffices
      },
      {
        path: '/orders/checkout/:id',
        component: PageCheckout
      },
      {
        path: '/orders/thanks/:id',
        component: PageOrderThanks
      },
      {
        path: '/product/:type/:id',
        mode: 'catalog',
        component: PageProductRedirect
      },
      {
        path: '/product/:id',
        component: props => (
          <Redirect to={`/catalog/product/${prepareRedirect(props, 'id')}`} />
        )
      },
      {
        path: '/recovery',
        component: PageRecovery
      },
      {
        path: '/registration-confirm',
        component: PageRegistrationConfirm
      },
      {
        path: '/ruler',
        type: 'ruler',
        component: PageDirectory
      },
      {
        path: '/search',
        component: PageSearch
      },
      {
        path: '/search-moderation/:site?/:tab?/:filterState?',
        component: PageSearchModeration,
        requestParams: {
          site: ':site',
          tab: ':tab',
          filterState: ':filterState'
        }
      },
      {
        path: '/server-error',
        status: 500,
        component: ServerError
      },
      {
        path: '/sitemap',
        component: PageSitemap
      },
      {
        path: '/storage/section/:id',
        component: PageStorageSection
      },
      {
        path: '/storage',
        component: PageStorage
      },
      {
        path: '/tm',
        type: 'tm',
        component: PageDirectory
      },
      {
        path: '*',
        status: 404,
        component: PageNotFound
      }
    ]
  }
]

export default routes
