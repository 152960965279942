import React from 'react'

import { array, string, number, func, oneOf } from 'prop-types'
import { always, compose, cond, equals, isEmpty, prop, propOr, reduce, T, toLower, values } from 'ramda'

import NoticeBody from 'components/Notice/NoticeBody'
import Button from 'UI/Button'

import { checkIsButtonDelete } from './getIcon'
import s from './NoticeBody.scss'

const IS_HONEST_SIGN = 'need_change_contractor_edo'

const ButtonsBasket = ({
  buttons = [],
  code,
  type,
  switchItemInGroup,
  onSetChecked,
  onShowModal,
  pageType,
  bookmark,
  onNavigate
}) => {

  const onSuccessCorrect = () => {
    onNavigate(`/basket/${pageType}/${bookmark}`)
  }

  const onCorrect = (_code, product) => () => {
    if (IS_HONEST_SIGN === _code) {
      if (product) {
        const productToChecked = compose(
          reduce((acc, item) => ({ ...acc, [item]: true }), {}),
          values
        )(product)
        onSetChecked(productToChecked)
      }
      onShowModal('cart-move')
      return true
    }
    const params = {
      action: 'correct',
      error_code: _code,
      catalog: pageType,
      value: bookmark
    }
    return switchItemInGroup({
      id: bookmark || pageType,
      type: 'basket',
      params,
      onSuccess: onSuccessCorrect
    })
  }

  let color = cond([
    [equals('error'), always('blue')],
    [equals('warning'), always('yellow')],
    [equals('pronibit'), always('yellow')],
    [equals('success'), always('green')],
    [equals('notice'), always('gray')],
    [equals('info'), always('blue')],
    [equals('system'), always('black')],
    [T, () => 'blue']
  ])(type)

  if (equals(type, 'error') && checkIsButtonDelete(buttons)) {
    color = 'red'
  }

  if (isEmpty(buttons)) {
    return null
  }

  return (
    <>
      {
        buttons.map(item => (
          <Button
            key={prop('TEXT', item)}
            color={color}
            size='big'
            onClick={onCorrect(code, propOr([], 'PRODUCT', item))}
            stretched
          >
            {propOr('', 'TEXT', item)}
          </Button>
        ))}
    </>
  )
}

ButtonsBasket.propTypes = {
  type: oneOf([
    'error',
    'warning',
    'success',
    'notice',
    'info',
    'system',
    'loyalty'
  ]),
  code: string,
  buttons: array,
  switchItemInGroup: func,
  onSetChecked: func,
  onShowModal: func,
  pageType: string,
  bookmark: number,
  onNavigate: func
}

const NoticesBody = ({
  items = [],
  pageType,
  bookmark,
  switchItemInGroup = () => { },
  onNavigate = () => { }
}) => {
  if (isEmpty(items)) {
    return null
  }
  return (
    <div className={s.container}>
      {items.map((item, key) => (
        <NoticeBody
          // eslint-disable-next-line react/no-array-index-key
          key={key}
          text={prop('TEXT', item)}
          type={toLower(propOr('', 'TYPE', item))}
          buttons={isEmpty(propOr([], 'BUTTON', item)) ? [] :
            <ButtonsBasket
              buttons={propOr([], 'BUTTON', item)}
              type={toLower(propOr('', 'TYPE', item))}
              pageType={pageType}
              bookmark={bookmark}
              code={prop('CODE', item)}
              switchItemInGroup={switchItemInGroup}
              onNavigate={onNavigate}
            />
          }

        />
      ))}
    </div>
  )
}

NoticesBody.propTypes = {
  items: array,
  pageType: string,
  bookmark: number,
  switchItemInGroup: func,
  onNavigate: func
}

export default NoticesBody
