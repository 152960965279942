/* eslint-disable complexity */
import R, {
  isEmpty,
  mergeDeepRight,
  mergeRight,
  propOr,
  prop,
  path,
  pathOr,
  concat,
  pick,
  map,
  length,
  reduce,
  values,
  omit,
  uniq,
  assoc,
  set,
  over,
  lensPath,
  difference,
  equals,
  keys,
  includes,
  head,
  compose
} from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import objectToQuery from 'helpers/objectToQuery'
import { queryParamsKeys } from 'helpers/productListParams'
import { getOrderData } from 'helpers/products'
import { fetchCountSuccess } from 'redux/modules/favorite'
import { showModal } from 'redux/modules/modal'
import { setMarkdownDetail } from 'redux/modules/offers'
import {
  switchItemInGroup,
  putProducts as putInProductList
} from 'redux/modules/productList'
import {
  applyFilters,
  setFilters,
  initFilters
} from 'redux/modules/productsFilter'
import { setParams } from 'redux/modules/productsParams'

import { getBreadcrumbs } from './selector'

const LENGTH_2 = 2

const initialState = {
  productModal: '',
  analogsModal: '',
  productBarcodeModal: '',
  basket: 'main',
  itemsIdList: [],
  itemsById: {},
  itemsOrderById: {},
  itemsCheckedMap: {},
  metadata: {},
  isLoading: false,
  isLoaded: false,
  isError: false,
  error: null,
  orderList: [],
  preventNavigation: false,
  isNewPage: false,
  isInStock: false,
  pageId: ''
}

export {
  getItemById,
  getOrderList,
  getItemMinById,
  getItemCountById,
  getItemsById,
  getItemOrderById,
  makeGetItemOrder,
  selectProducts,
  marksSelector,
  storesSelector,
  remainSelector,
  amountSelector,
  productSelector,
  activeStoreSelector,
  storeSelector,
  checkedSelector,
  buySelector,
  bookmarksSelector,
  offersSelector,
  noticeSelector,
  itemSelector,
  preparePropertySelector,
  productTabsSelector,
  productCertsSelector,
  productPhotosSelector,
  productGalleryPhotoSelector,
  productGallerySettingsSelector,
  galleryModalSettingsSelector,
  productGallerySpriteSelector,
  redirectIsLoadedSelector,
  redirectIdSelector,
  prepareParamsSelector,
  productModalSelector,
  productBarcodeModalSelector,
  analogsModalSelector
} from './selector'

export const fetch = createAction('products/FETCH')
export const fetchSuccess = createAction('products/FETCH_SUCCESS')
const fetchFailure = createAction('products/FETCH_FAILURE')
export const fetchSpritePhoto = createAction('products/FETCH_SPRITE_PHOTO')
export const fetchSpritePhotoSuccess = createAction(
  'products/FETCH_SPRITE_SUCCESS'
)
const fetchSpritePhotoFailure = createAction('products/FETCH_SPRITE_FAILURE')
export const clearCheckedItems = createAction('products/CLEAR_CHECKED_ITEMS')
export const updateGroup = createAction('products/UPDATE_GROUP')
export const updateGroupSuccess = createAction('products/UPDATE_GROUP_SUCCESS')
export const updateGroupFailure = createAction('products/UPDATE_GROUP_FAILURE')
export const setActiveStore = createAction('products/SET_ACTIVE_STORE')
export const updateItemsOrder = createAction('products/UPDATE_ITEMS_ORDER')
export const setOrderData = createAction('products/SET_ORDER_DATA')
export const setStatus = createAction('products/SET_STATUS')
export const setChecked = createAction('products/SET_CHECKED')
export const setCount = createAction('products/SET_COUNT')
export const resetPageId = createAction('products/RESET_PAGEID')
export const resetPreventNavigation = createAction(
  'products/RESET_PREVENT_NAVIGATION'
)
export const resetSearch = createAction('products/RESET_SEARCH')
export const removeItems = createAction('products/REMOVE_ITEMS')
export const pushAllGroupToBasket = createAction(
  'products/PUSH_GROUP_TO_BASKET'
)
export const setMultiplicity = createAction('products/SET_MULTIPLICITY')
export const deleteProduct = createAction('products/DELETE_PRODUCT')
export const resetBreadCrumbs = createAction('products/RESET_BREADCRUMBS')
export const setMarks = createAction('products/SET_MARKS')
export const clearFavorite = createAction('products/CLEAR_FAVORITE')
export const fetchToRedirect = createAction('products/FETCH_TO_REDIRECT')
export const fetchToRedirectSuccess = createAction(
  'products/FETCH_TO_REDIRECT_SUCCESS'
)
export const fetchToRedirectFailure = createAction(
  'products/FETCH_TO_REDIRECT_FAILURE'
)
export const setForPoints = createAction('products/SET_FOR_POINTS')
export const setProductModal = createAction('products/SET_PRODUCT_MODAL')
export const setAnalogsModal = createAction('products/SET_ANALOGS_MODAL')
export const setProductBarcodeModal = createAction(
  'products/SET_PRODUCT_BARCODE_MODAL'
)

const request =
  ({ clientApi }) =>
    ({ url, params, filter, type, isFullRequest }) =>
      clientApi
        .get(`/v3/${url}/${objectToQuery({ filter })}`, { params })
        .then(data =>
          fetchSuccess({
            dataPayload: data,
            url,
            params,
            type,
            isFullRequest
          })
        )
        .catch(data => fetchFailure(data))

const handleFetch = (
  state,
  {
    pageId,
    url,
    query,
    type,
    params = {},
    filters = {},
    count,
    basket = 'main',
    forceUpdate,
    isUpdateFilters,
    isResetFilters
  },
  { clientApi }
) => {
  const effects = []
  effects.push(Effects.call(setParams, params))
  const contractorId = clientApi.getContractorId()
  const isNewPage = forceUpdate || pageId !== prop('pageId', state)
  const uri =
    isNewPage || isResetFilters || propOr('', 'type', params) === 'favorite'
      ? ''
      : '/items'

  const filter = isResetFilters ? {} : { ...filters }
  if (isNewPage) {
    const checked = filters && !isEmpty(filters) ? filters : {}
    effects.push(Effects.call(applyFilters, { checkedItems: { ...checked } }))
  } else if (isUpdateFilters) {
    effects.push(Effects.call(applyFilters))
  } else if (isResetFilters) {
    effects.push(Effects.call(applyFilters, { checkedItems: {} }))
  }
  const queryParams = {
    ...pick(queryParamsKeys, params),
    ...query,
    contractor_id: contractorId
  }
  if (isNewPage) {
    effects.push(Effects.call(initFilters, { url, query }))
  }
  effects.push(
    Effects.promise(request({ clientApi }), {
      url: `${url}${uri}`,
      params: queryParams,
      filter,
      type,
      isFullRequest: !uri
    })
  )

  if (count !== undefined) {
    effects.push(Effects.call(setParams, { count }))
  }

  return loop(
    {
      ...state,
      pageId,
      basket,
      fixedSearch: undefined,
      isLoading: true,
      isLoaded: false
    },
    Effects.batch(effects)
  )
}

const handleFetchSuccess = (
  state,
  { dataPayload, isFullRequest, type: catalogType, params }
) => {
  const data = pathOr({}, ['data', 'response'], dataPayload)
  const config = propOr({}, 'config', dataPayload)
  const nav = {}
  if (isFullRequest) {
    const bookmark = Number(propOr(0, 'bookmark', params))
    const items = path(['NAV', 'BREADCRUMB'], data)
    nav.title = pathOr('', ['NAV', 'TITLE'], data)
    nav.breadcrumbs = getBreadcrumbs(catalogType, items, bookmark)
  }

  let type = 'section'
  /**
currentSlide  * По другому пока что ни как
  */
  if (prop('url', config) === '/v3/personal/compare/catalog/') {
    type = 'compare'
  }
  let fixedSearch
  const products = propOr([], 'ITEMS', data)
  const isError = length(products) === 0 && path(['NAV', 'CNT'], data) !== 0
  const itemsIdList = map(prop('ID'), products)
  const itemsById = {
    ...prop('itemsById', state),
    ...reduce(
      (acc, item) => ({ ...acc, [prop('ID', item)]: item }),
      {},
      products
    )
  }
  const catalog = prop('bookmark', params)
    ? ['bookmark', prop('bookmark', params)]
    : [propOr('main', 'basket', state), 0]
  const itemsOrderById = {
    ...prop('itemsOrderById', state),
    ...reduce(
      (acc, item) => ({
        ...acc,
        [prop('ID', item)]: getOrderData(item, catalog)
      }),
      {},
      products
    )
  }

  const itemsCheckedMap = prop('itemsCheckedMap', state)
  const metadata = omit(['FILTER', 'PRODUCTS', 'NAV'], data)
  const orderList = values(
    pathOr(prop('orderList', state), ['NAV', 'SORT'], data)
  )
  const error = values(pathOr({}, ['data', 'errors'], dataPayload))[0]
  const notice = compose(
    head,
    values,
    pathOr({}, ['data', 'notices'])
  )(dataPayload)
  const effects = []
  const count = isError ? 0 : path(['NAV', 'CNT'], data)
  if (catalogType === 'favorite') {
    effects.push(
      Effects.call(fetchCountSuccess, {
        data: { response: { NAV: { FAVORITE: { CNT: count } } } }
      })
    )
  }
  if (count !== undefined) {
    effects.push(Effects.call(setParams, { count }))
  }
  if (!error && prop('AGGREGATIONS', data)) {
    effects.push(Effects.call(setFilters, dataPayload))
  }
  if (catalogType === 'markdown') {
    const detailInfo = propOr({}, 'NAV', data)
    effects.push(Effects.call(setMarkdownDetail, detailInfo))
  }
  effects.push(Effects.call(putInProductList, { itemsById, type }))
  return loop(
    {
      ...state,
      ...nav,
      metadata,
      itemsIdList,
      itemsById,
      itemsOrderById,
      fixedSearch,
      itemsCheckedMap,
      isLoading: false,
      isLoaded: true,
      isError,
      error,
      orderList,
      notice
    },
    Effects.batch(effects)
  )
}

const handlePushAllGroupToBasket = (state, { params }) => {
  const itemsOrderById = { ...state.itemsOrderById }
  const settings = {
    skipWarnings: true,
    catalog: propOr('main', 'basket', state)
  }
  const items = {}
  Object.keys(itemsOrderById).forEach(id => {
    items[id] = path([id, 'amount'], itemsOrderById)
  })
  return loop(
    {
      ...state
    },
    Effects.call(updateGroup, {
      id: 0,
      type: 'basket',
      items,
      params,
      settings
    })
  )
}

const onUpdateGroupSuccess = payload => data =>
  Effects.call(updateGroupSuccess, { ...payload, data })

const handleUpdateGroup = (
  state,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { type, items, notResetStatusOnSuccess, ...rest }
) => {
  const itemsOrderById = { ...state.itemsOrderById }
  Object.keys(items).forEach(id => {
    itemsOrderById[id] = set(
      lensPath(['isUpdating', type]),
      true,
      itemsOrderById[id]
    )
  })

  return loop(
    {
      ...state,
      itemsOrderById
    },
    Effects.call(switchItemInGroup, {
      ...rest,
      type,
      items,
      onSuccess: onUpdateGroupSuccess({ type, items, ...rest })
    })
  )
}

const handleUpdateGroupSuccess = (state, { type, items, data }) => {
  let itemsById = { ...state.itemsById }
  let itemsOrderById = { ...state.itemsOrderById }
  const response = pathOr({}, ['data', 'response'], data)
  let products = propOr({}, 'ITEMS', response)
  let productIds = keys(items)

  if (!includes(type, ['basket', 'loyaltyBasket'])) {
    const key = type.toUpperCase()
    products = (keys(response.ADDED) || []).reduce(
      (acc, id) => ({ ...acc, [id]: { [key]: true } }),
      {}
    )
  }

  if (!isEmpty(products)) {
    const productAssoc = reduce(
      (acc, cur) => assoc(prop('ID', cur), cur, acc),
      {}
    )(products)
    itemsById = mergeRight(itemsById, productAssoc)
    productIds = uniq(concat(keys(productAssoc), productIds))
  }
  const productOrderIds = reduce(
    (acc, item) => ({ ...acc, [item]: { isUpdating: { [type]: false } } }),
    {},
    productIds
  )
  itemsOrderById = mergeDeepRight(itemsOrderById, productOrderIds)

  return {
    ...state,
    itemsById,
    itemsOrderById
  }
}

const handleUpdateGroupFailure = state => state

const handleSetOrderData = (
  state,
  { id, data, items, basket = state.basket }
) => ({
  ...(items
    ? over(lensPath(['itemsOrderById']), orders => ({ ...orders, ...items }))(
      state
    )
    : over(lensPath(['itemsOrderById', id]), order => ({ ...order, ...data }))(
      state
    )),
  basket
})

const handleSetActiveStore = (state, { store, id }) =>
  set(lensPath(['itemsOrderById', id, 'activeStore']), store)(state)

const handleUpdateItemsOrder = (state, { items }) => ({
  ...state,
  itemsOrderById: {
    ...mergeDeepRight(state.itemsOrderById, items)
  }
})

const handleFetchFailure = state => ({
  ...state,
  isError: true,
  isLoading: false,
  isLoaded: false
})

// @param {Object | Boolean} payload Boolean изменяет состояние всех товаров
// (true - выделяет, false - снимает выделение)
const handleSetChecked = (state, payload) => {
  let itemsCheckedMap = {}
  if (typeof payload === 'object') {
    itemsCheckedMap = { ...state.itemsCheckedMap, ...payload }
  } else if (payload === true) {
    itemsCheckedMap = mergeDeepRight(
      state.itemsCheckedMap,
      state.itemsIdList.reduce((acc, id) => ({ ...acc, [id]: true }), {})
    )
  } else if (payload === false) {
    itemsCheckedMap = mergeDeepRight(
      state.itemsCheckedMap,
      state.itemsIdList.reduce((acc, id) => ({ ...acc, [id]: false }), {})
    )
  }
  return {
    ...state,
    itemsCheckedMap
  }
}

const handleSetCount = (state, { type = 'catalog', id, amount }) =>
  set(lensPath(['itemsById', id, 'amount', type]), amount)(state)

const handleSetStatus = (state, payload) => ({
  ...state,
  ...payload
})

const handleRemoveItems = (state, payload) => {
  const removeIds = [].concat(payload)
  const itemsIdList = difference(state.itemsIdList, removeIds)
  return {
    ...state,
    itemsIdList
  }
}

const handleResetPageId = state => ({
  ...state,
  pageId: ''
})

const handleResetPreventNavigation = state => ({
  ...state
})

const handleClearCheckedItems = state => ({
  ...state,
  itemsCheckedMap: {}
})

const handleResetSearch = (state, payload) => {
  const value = prop('value', payload)
  const isError = length(value) < LENGTH_2 || equals(value, 0)
  return {
    ...state,
    itemsIdList: [],
    itemsById: {},
    itemsOrderById: {},
    metadata: {},
    isLoading: !isError,
    isLoaded: false,
    isError
  }
}

const handleSetMultiplicity = (state, { id, value }) =>
  set(lensPath(['itemsOrderById', id, 'isNotMultiple']), !value, state)

const handleDelete = (state, { id, type }) =>
  set(lensPath(['itemsById', id, 'BASKETS', type]), {}, state)

const handleResetBreadCrumbs = state => ({
  ...state,
  breadcrumbs: []
})

const handleSetMarks = (state, payload = {}) =>
  R.set(
    R.lensPath(['metadata', 'ENTITIES']),
    R.mergeDeepRight(R.pathOr({}, ['metadata', 'ENTITIES'], state), payload),
    state
  )

const handleClearFavorite = state => ({
  ...state,
  itemsById: map(
    item => ({
      ...item,
      FAVORITE: false
    }),
    state.itemsById
  )
})

const requestSprite =
  ({ clientApi }) =>
    ({ id, url }) =>
      clientApi
        .get(`/sprite/?url=${url}`)
        .then(data => fetchSpritePhotoSuccess({ data, id }))
        .catch(data => fetchSpritePhotoFailure({ data, id }))

const handleFetchSpritePhoto = (state, { id, url }, { clientApi }) =>
  loop(
    {
      ...state,
      sprite: {
        ...state.sprite,
        [id]: {
          data: {},
          isLoading: true,
          isLoaded: false
        }
      }
    },
    Effects.promise(requestSprite({ clientApi }), { id, url })
  )

const handleFetchSpritePhotoSuccess = (state, { data, id }) => ({
  ...state,
  sprite: {
    ...state.sprite,
    [id]: {
      data: pathOr({}, ['data', 'response'], data),
      isLoading: false,
      isLoaded: true,
      error: pathOr('', ['data', 'error', 'message'], data)
    }
  }
})

const handleFetchSpritePhotoFailure = (state, { data, id }) => ({
  ...state,
  sprite: {
    ...state.sprite,
    [id]: {
      data: {},
      isLoading: false,
      isLoaded: false,
      error: propOr('Ошибка загрузки файла', 'message', data)
    }
  }
})

const requestToRedirect =
  ({ clientApi }) =>
    ({ id, type }) =>
      clientApi
        .get(`/v3/catalog/main/product/by/${type}/${id}/`, {
          params: {
            'select[]': 'ID'
          }
        })
        .then(fetchToRedirectSuccess)
        .catch(fetchToRedirectFailure)

const handleFetchToRedirect = (state, { type, id }, { clientApi }) =>
  loop(
    {
      ...state,
      toRedirect: {
        isLoading: true,
        isLoaded: false,
        productId: ''
      }
    },
    Effects.promise(requestToRedirect({ clientApi }), { id, type })
  )

const handleFetchToRedirectSuccess = (state, payload) => ({
  ...state,
  toRedirect: {
    isLoading: false,
    isLoaded: true,
    productId: path(['data', 'response', 'ITEM', 'ID'], payload)
  }
})

const handleFetchToRedirectFailure = (state, error) => ({
  ...state,
  toRedirect: {
    isLoading: false,
    isLoaded: false,
    productId: '',
    error
  }
})

const handleSetForPoints = (state, { status, id }) =>
  set(lensPath(['itemsOrderById', id, 'isForPoints']), status)(state)

const handleSetProductModal = (state, id) => {
  const effects = []
  if (id) {
    effects.push(Effects.call(showModal, 'quickView'))
  }
  return loop(
    {
      ...state,
      productModal: id
    },
    Effects.batch(effects)
  )
}

const handleSetAnalogsModal = (state, id) => {
  const effects = []
  if (id) {
    effects.push(Effects.call(showModal, 'analogsModal'))
  }
  return loop(
    {
      ...state,
      analogsModal: id
    },
    Effects.batch(effects)
  )
}

const handleSetProductBarcodeModal = (state, id) => {
  const effects = []
  if (id) {
    effects.push(Effects.call(showModal, 'barcode'))
  }
  return loop(
    {
      ...state,
      productBarcodeModal: id
    },
    Effects.batch(effects)
  )
}

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(fetchSpritePhoto, handleFetchSpritePhoto)
  on(fetchSpritePhotoSuccess, handleFetchSpritePhotoSuccess)
  on(fetchSpritePhotoFailure, handleFetchSpritePhotoFailure)
  on(updateGroup, handleUpdateGroup)
  on(updateGroupSuccess, handleUpdateGroupSuccess)
  on(updateGroupFailure, handleUpdateGroupFailure)
  on(setOrderData, handleSetOrderData)
  on(setStatus, handleSetStatus)
  on(resetPageId, handleResetPageId)
  on(resetSearch, handleResetSearch)
  on(resetPreventNavigation, handleResetPreventNavigation)
  on(setChecked, handleSetChecked)
  on(setCount, handleSetCount)
  on(removeItems, handleRemoveItems)
  on(clearCheckedItems, handleClearCheckedItems)
  on(pushAllGroupToBasket, handlePushAllGroupToBasket)
  on(setMultiplicity, handleSetMultiplicity)
  on(deleteProduct, handleDelete)
  on(resetBreadCrumbs, handleResetBreadCrumbs)
  on(setActiveStore, handleSetActiveStore)
  on(updateItemsOrder, handleUpdateItemsOrder)
  on(setMarks, handleSetMarks)
  on(clearFavorite, handleClearFavorite)
  on(fetchToRedirect, handleFetchToRedirect)
  on(fetchToRedirectSuccess, handleFetchToRedirectSuccess)
  on(fetchToRedirectFailure, handleFetchToRedirectFailure)
  on(setForPoints, handleSetForPoints)
  on(setProductModal, handleSetProductModal)
  on(setAnalogsModal, handleSetAnalogsModal)
  on(setProductBarcodeModal, handleSetProductBarcodeModal)
}, initialState)

export default reducer
