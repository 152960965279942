import { path, prop, find, propEq, pathOr, propOr, map, isEmpty } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { showModal } from 'redux/modules/modal'

export {
  getIsLoading,
  getIsLoaded,
  getSelectedContractorIndex,
  getContractorValues,
  getOrganizations,
  getMinDate,
  getMaxDate,
  getDateRegisterClose,
  getActs,
  getIsError
} from './selector'

const initialState = {
  organizations: [],
  contractorValues: [],
  items: [],
  bookkeepingOrgs: [],
  bookkeepingContractors: [],
  status: {},
  actEmail: '',
  contractorId: '',
  date: {
    max: 0,
    min: 0
  },
  dateRegisterClose: 0,
  selectedContractorIndex: -1,
  isLoading: false,
  isLoaded: true,
  isError: false
}

export const fetchAct = createAction('bookkeeping/REQUEST_ACT')
export const fetchActSuccess = createAction('bookkeeping/REQUEST_ACT_SUCCESS')
export const fetchOrganization = createAction(
  'bookkeeping/REQUEST_ORGANIZATION'
)
export const fetchOrganizationSuccess = createAction(
  'bookkeeping/REQUEST_ORGANIZATION_SUCCESS'
)
export const fetchActCreate = createAction('bookkeeping/REQUEST_ACT_CREATE')
export const fetchActCreateSuccess = createAction(
  'bookkeeping/REQUEST_ACT_CREATE_SUCCESS'
)
export const fetchActRepeat = createAction('bookkeeping/REQUEST_ACT_REPEAT')
export const fetchFailure = createAction('bookkeeping/REQUEST_FAILURE')

const requestAct =
  ({ clientApi }) =>
    () =>
      clientApi
        .get('/v3/contractor/bookkeeping/act/', {
          params: {
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(fetchActSuccess)
        .catch(fetchFailure)

const requestOrganization =
  ({ clientApi }) =>
    params =>
      clientApi
        .get('/v3/contractor/bookkeeping/organization/', {
          params: {
            contractor_id: clientApi.getContractorId(),
            ...params
          }
        })
        .then(fetchOrganizationSuccess)
        .catch(fetchFailure)

const requestActCreate =
  ({ clientApi }) =>
    params =>
      clientApi
        .post('/v3/contractor/bookkeeping/act/create/', {
          params: {
            contractor_id: clientApi.getContractorId(),
            ...params
          }
        })
        .then(fetchActSuccess)
        .catch(fetchFailure)

const requestActRepeat =
  ({ clientApi }) =>
    ({ id }) =>
      clientApi
        .post(`/v3/contractor/bookkeeping/act/repeat/${id}/`, {
          params: {
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(fetchActCreateSuccess)
        .catch(fetchFailure)

const handleFetchAct = (state, _, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false,
      organizations: [],
      contractorId: clientApi.getContractorId()
    },
    Effects.promise(requestAct({ clientApi }))
  )

const handleFetchActSuccess = (state, payload, { clientApi }) => {
  const response = path(['data', 'response'], payload)
  const aggregations = prop('AGGREGATIONS', response)
  const entities = prop('ENTITIES', response)
  const contractorId = prop('contractorId', state)
  const effects = []
  let isLoading = false
  let isLoaded = true

  const contractor = find(
    item => propEq('CODE', 'contractor', item),
    aggregations
  )
  const contractorValues = propOr([], 'VALUES', contractor)
  const date = find(item => propEq('CODE', 'date', item), aggregations)

  if (contractorId) {
    isLoading = true
    isLoaded = false
    effects.push(
      Effects.promise(requestOrganization({ clientApi }), {
        contractor: contractorId
      })
    )
  }

  return loop(
    {
      ...state,
      dateRegisterClose: path(['SETTINGS', 'DATE_REGISTER_CLOSE'], entities),
      items: propOr([], 'ITEMS', response),
      status: propOr({}, 'UF_STATUS', entities),
      bookkeepingOrgs: propOr({}, 'UF_BOOKKEEPING_ORG', entities),
      bookkeepingContractors: propOr({}, 'UF_CONTRACTOR', entities),
      date: {
        max: propOr(0, 'MAX', date),
        min: propOr(0, 'MIN', date)
      },
      selectedContractorIndex: contractorId,
      contractorValues,
      isLoading,
      isLoaded
    },
    Effects.batch(effects)
  )
}

const handleFetchOrganization = (state, contractor, { clientApi }) => {
  if (isEmpty(contractor)) return { ...state, organizations: [] }

  return loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestOrganization({ clientApi }), { contractor })
  )
}

const handleFetchOrganizationSuccess = (state, payload) => {
  const response = pathOr({}, ['data', 'response'], payload)
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    organizations: map(index => response[index], Object.keys(response))
  }
}

const handleFetchActCreate = (
  state,
  { contractor, organization, dateFrom, dateTo },
  { clientApi }
) => {
  const params = {
    contractor,
    organization,
    date: {
      MIN: dateFrom,
      MAX: dateTo
    }
  }

  return loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false,
      contractorId: contractor
    },
    Effects.batch([
      Effects.call(showModal, 'bookkeeping'),
      Effects.promise(requestActCreate({ clientApi }), params)
    ])
  )
}

const handleFetchActCreateSuccess = (state, payload) =>
  loop(
    {
      ...state,
      isLoading: false,
      isLoaded: true
    },
    Effects.call(fetchActSuccess, payload)
  )

const handleFetchActRepeat = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestActRepeat({ clientApi }), { id: payload })
  )

const handleFetchFailure = state =>
  loop(
    {
      ...state,
      isLoading: false,
      isLoaded: true,
      isError: true
    },
    Effects.call(showModal, 'bookkeeping')
  )

const reducer = createReducer(on => {
  on(fetchAct, handleFetchAct)
  on(fetchFailure, handleFetchFailure)
  on(fetchActSuccess, handleFetchActSuccess)
  on(fetchOrganization, handleFetchOrganization)
  on(fetchOrganizationSuccess, handleFetchOrganizationSuccess)
  on(fetchActCreate, handleFetchActCreate)
  on(fetchActCreateSuccess, handleFetchActCreateSuccess)
  on(fetchActRepeat, handleFetchActRepeat)
}, initialState)

export default reducer
