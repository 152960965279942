import { compose, set, lensPath, lensProp, pathOr, propOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { fetchSuccess as fetchProductsSuccess } from 'redux/modules/products'

const initialState = {
  data: {},
  sections: {},
  products: {},
  manufacture: {},
  meta: {},
  title: ''
}

export {
  brandDataSelector,
  brandLoadedSelector,
  brandLoadingSelector,
  getBrand,
  getBrandData,
  isBrandLoading,
  isBrandLoaded,
  isBrandSectionsLoaded,
  isBrandProductsLoaded,
  allSectionsSelector,
  brandProductsSelector,
  brandSectionsSelector,
  brandSectionsLoadedSelector,
  brandSectionsLoadingSelector,
  getBrandCode,
  titleSelector,
  headSelector,
  manufactureDetailSelector
} from './selector'

export const fetchBrandsDetail = createAction('brands/GET_DETAIL_BRANDS')
export const fetchBrandsDetailSuccess = createAction(
  'brands/GET_DETAIL_BRANDS_SUCCESS'
)
export const fetchBrandsDetailFailure = createAction(
  'brands/GET_DETAIL_BRANDS_FAILURE'
)

export const fetchBrandsCarousel = createAction('brands/FETCH_BRANDS_CAROUSEL')
export const fetchBrandsCarouselSuccess = createAction(
  'brands/FETCH_BRANDS_CAROUSEL_SUCCESS'
)
export const fetchBrandsCarouselFailure = createAction(
  'brands/FETCH_BRANDS_CAROUSEL_FAILURE'
)

export const fetchManufacturesDetail = createAction(
  'brands/GET_DETAIL_MANUFACTURES'
)
export const fetchManufacturesDetailSuccess = createAction(
  'brands/GET_DETAIL_MANUFACTURES_SUCCESS'
)
export const fetchManufacturesDetailFailure = createAction(
  'brands/GET_DETAIL_MANUFACTURES_FAILURE'
)

export const request =
  ({ clientApi }) =>
    ({ type = 'tm', id, section }) => {
      const isSection = section === 'sections'
      const url = isSection
        ? '/v3/catalog/main/sections/'
        : `/v3/catalog/main/directories/${type}/${id}/`
      const params = {
        contractor_id: clientApi.getContractorId(),
        depth: 3
      }
      return clientApi
        .get(url, { params })
        .then(payload => fetchBrandsDetailSuccess({ payload, id, section }))
        .catch(payload =>
          fetchBrandsDetailFailure({ data: { ...payload }, section })
        )
    }

const requestCarousel =
  ({ clientApi }) =>
    ({ id }) => {
      const params = { limit: 15, contractor_id: clientApi.getContractorId() }
      return clientApi
        .get(`/v3/catalog/main/directory/tm/${id}/section/`, { params })
        .then(payload => fetchBrandsCarouselSuccess({ payload, id }))
        .catch(fetchBrandsCarouselFailure)
    }

const requestManufacture =
  ({ clientApi }) =>
    ({ id }) => {
      const params = { contractor_id: clientApi.getContractorId() }
      return clientApi
        .get(`/v3/catalog/main/directories/manufacture/${id}/`, { params })
        .then(payload => fetchManufacturesDetailSuccess({ payload, id }))
        .catch(fetchManufacturesDetailFailure)
    }

const handleFetchDetailBrands = (
  state,
  { type = 'tm', id, section },
  { clientApi }
) =>
  loop(
    compose(
      set(lensPath([section || 'data', 'error']), null),
      set(lensPath([section || 'data', 'isLoading']), true)
    )(state),
    Effects.promise(request({ clientApi }), { type, id, section })
  )

const handleFetchDetailBrandsSuccess = (
  state,
  { payload, id, section = 'data' }
) => {
  const item = section === 'sections' ? 'ITEMS' : 'ITEM'
  const response = pathOr([], ['data', 'response', item], payload)
  const meta = pathOr(state.meta, ['data', 'response', 'META'], payload)
  const title = pathOr(
    state.title,
    ['data', 'response', 'NAV', 'TITLE'],
    payload
  )

  return compose(
    set(lensProp('meta'), meta),
    set(lensProp('title'), title),
    set(lensPath([section, id]), response),
    set(lensPath([section, 'isLoading']), false)
  )(state)
}

const handleFetchDetailBrandsFailure = (state, { data, section = 'data' }) =>
  compose(
    set(lensPath([section, 'error']), getErrorMessage(data)),
    set(lensPath([section, 'isLoading']), false)
  )(state)

const handleFetchBrandsCarousel = (state, { id, section }, { clientApi }) =>
  loop(
    compose(
      set(lensPath([section, 'error']), null),
      set(lensPath([section, 'isLoading']), true)
    )(state),
    Effects.promise(requestCarousel({ clientApi }), { id })
  )

const handleFetchBrandsCarouselSuccess = (state, payload) => {
  const products = pathOr(
    [],
    ['payload', 'data', 'response', 'PRODUCTS'],
    payload
  )
  const brandId = propOr(0, 'id', payload)
  return loop(
    {
      ...state,
      products: {
        [brandId]: products,
        isLoading: false
      }
    },
    Effects.call(fetchProductsSuccess, { dataPayload: payload.payload })
  )
}

const handleFetchBrandsCarouselFailure = (state, payload) =>
  compose(
    set(lensPath(['products', 'error']), getErrorMessage(payload)),
    set(lensPath(['products', 'isLoading']), false)
  )(state)

const handleFetchDetailManufactures = (state, { id }, { clientApi }) =>
  loop(
    compose(
      set(lensPath(['manufacture', 'error']), null),
      set(lensPath(['manufacture', 'isLoading']), true)
    )(state),
    Effects.promise(requestManufacture({ clientApi }), { id })
  )

const handleFetchDetailManufacturesSuccess = (state, { payload, id }) => {
  const response = pathOr([], ['data', 'response', 'ITEM'], payload)
  const meta = pathOr(state.meta, ['data', 'response', 'META'], payload)
  const title = pathOr(
    state.title,
    ['data', 'response', 'NAV', 'TITLE'],
    payload
  )

  return compose(
    set(lensPath(['manufacture', 'meta']), meta),
    set(lensPath(['manufacture', 'title']), title),
    set(lensPath(['manufacture', id]), response),
    set(lensPath(['manufacture', 'isLoading']), false)
  )(state)
}

const handleFetchDetailManufacturesFailure = (state, { data }) =>
  compose(
    set(lensPath(['manufacture', 'error']), getErrorMessage(data)),
    set(lensPath(['manufacture', 'isLoading']), false)
  )(state)

const reducer = createReducer(on => {
  on(fetchBrandsDetail, handleFetchDetailBrands)
  on(fetchBrandsDetailSuccess, handleFetchDetailBrandsSuccess)
  on(fetchBrandsDetailFailure, handleFetchDetailBrandsFailure)
  on(fetchBrandsCarousel, handleFetchBrandsCarousel)
  on(fetchBrandsCarouselSuccess, handleFetchBrandsCarouselSuccess)
  on(fetchBrandsCarouselFailure, handleFetchBrandsCarouselFailure)
  on(fetchManufacturesDetail, handleFetchDetailManufactures)
  on(fetchManufacturesDetailSuccess, handleFetchDetailManufacturesSuccess)
  on(fetchManufacturesDetailFailure, handleFetchDetailManufacturesFailure)
}, initialState)

export default reducer
