import {
  prop,
  propOr,
  pathOr,
  sortBy,
  compose,
  toLower,
  filter,
  isEmpty,
  head,
  groupBy,
  keys,
  sort,
  map
} from 'ramda'
import { createSelector } from 'reselect'

export const getIsLoaded = state => state.isLoaded
export const getIsLoading = state => state.isLoading

export const brandsSectionSelector = createSelector(
  ({ brands }) => prop('sections', brands),
  ({ section = '' }) => section,
  (brands, section) => propOr({}, section, brands)
)

export const brandsSectionDataSelector = createSelector(
  brandsSectionSelector,
  brands => propOr([], 'data', brands)
)

export const brandsSelector = createSelector(
  ({ brands }) => brands,
  brands => brands
)

export const brandsDataSelector = createSelector(brandsSelector, brands =>
  prop('data', brands)
)

export const brandsSortedSelector = createSelector(brandsDataSelector, brands =>
  sortBy(compose(toLower, prop('NAME')))(brands)
)

export const filterSymbolSelector = createSelector(
  ({ symbol = '' }) => symbol,
  symbol => symbol
)

export const filterSectionSelector = createSelector(
  ({ section = '' }) => section,
  section => section
)

export const brandsTotalSelector = createSelector(
  brandsSelector,
  brandsSectionSelector,
  filterSectionSelector,
  (brands, brandsSection, section) =>
    isEmpty(section) ? brands : brandsSection
)

const prepareBrandsByGroups = createSelector(
  brands => propOr([], 'data', brands),
  brands => propOr({}, 'groups', brands),
  (brands, groups) => {
    const groupNames = keys(groups)
    return map(group => ({
      type: group,
      title: pathOr('', [group, 'NAME'], groups),
      items: filter(pathOr(false, ['GROUPS', group]), brands)
    }))(groupNames)
  }
)

export const brandsGroupsSelector = createSelector(
  brandsTotalSelector,
  brandsList => prepareBrandsByGroups(brandsList)
)

export const getIsLoadedBrandsSelector = createSelector(
  brandsTotalSelector,
  brands => propOr(false, 'isLoaded', brands)
)

export const getIsLoadingBrandsSelector = createSelector(
  brandsTotalSelector,
  brands => propOr(true, 'isLoading', brands)
)

export const brandsTotalDataSelector = createSelector(
  brandsDataSelector,
  brandsSectionDataSelector,
  filterSectionSelector,
  (brands, brandsSection, section) =>
    isEmpty(section) ? brands : brandsSection
)

export const brandsGroupedSelector = createSelector(
  brandsTotalDataSelector,
  brands =>
    groupBy(
      item =>
        Number.isNaN(Number(head(propOr('-', 'NAME', item))))
          ? head(propOr('-', 'NAME', item))
          : '0-9',
      brands
    )
)

export const brandsFilteredSelector = createSelector(
  brandsTotalDataSelector,
  filterSymbolSelector,
  brandsGroupedSelector,
  (brands, symbol, brandsGrouped) =>
    isEmpty(symbol) ? brands : prop(symbol, brandsGrouped)
)

export const brandsLangSelector = createSelector(
  brandsGroupedSelector,
  (_, fn) => fn,
  (brands, fn) =>
    compose(
      sort((a, b) => a.localeCompare(b)),
      filter(fn),
      keys
    )(brands)
)

const manufacturesSelector = createSelector(
  state => state,
  state => propOr({}, 'manufactures', state)
)

export const manufacturesStrategicSelector = createSelector(
  manufacturesSelector,
  manufactures => prepareBrandsByGroups(manufactures)
)

export const isLoadedManufacturesSelector = createSelector(
  manufacturesSelector,
  manufactures => propOr(false, 'isLoaded', manufactures)
)
