import React, { FC } from 'react'

import cx from 'classnames'

import s from './HonestSign.scss'

const LINK = 'https://честныйзнак.рф'
const HEIGHT_35 = 35
const WIDTH_105 = 105

interface IHonestSign {
  className?: string
  height?: number
  isHint?: boolean
  isLink?: boolean
  width?: number
}

const HonestSign: FC<IHonestSign> = ({
  className,
  height = HEIGHT_35,
  isHint = false,
  isLink = true,
  width = WIDTH_105
}) => {
  const Tag = isLink ? 'a' : 'div'
  const imageStyle = { height: `${height}px`, width: `${width}px` }
  return (
    <div
      className={cx(s.honestSign, {
        [className!]: !!className
      })}
    >
      <Tag href={LINK}
        target='_blank'
        rel='noopener noreferrer'>
        <img
          alt='Честный знак'
          src='/images/honestSign/honestSign.png'
          style={imageStyle}
          title='Национальная система маркировки и прослеживания продукции'
        />
      </Tag>
      {isHint ? (
        <div className={s.hint}>
          Ознакомиться с системой «Честный знак» можно на{' '}
          <a href={LINK}
            target='_blank'
            rel='noopener noreferrer'>
            сайте
          </a>
        </div>
      ) : null}
    </div>
  )
}

export default HonestSign
